// src/services/AzureTextToSpeechService.ts

class AzureTextToSpeechService {
    private subscriptionKey: string;
    private region: string;
    private speakButton: HTMLButtonElement | null = null;
  
    constructor(subscriptionKey: string, region: string) {
      this.subscriptionKey = subscriptionKey;
      this.region = region;
      
      console.log('AzureTextToSpeechService initialized with region:', region);
    }
  
    /**
     * Metni sese dönüştürür ve bir indirme butonu oluşturur
     */
    public async speakText(
      text: string,
      languageCode: string,
      onStarted?: () => void,
      onCompleted?: () => void,
      onError?: (error: string) => void
    ): Promise<void> {
      try {
        if (!text || text.trim() === '') {
          if (onError) onError('Seslendirilecek metin boş olamaz.');
          return;
        }
  
        // Başlangıç callback'ini çağır
        if (onStarted) onStarted();
        console.log('TTS started for text:', text, 'language:', languageCode);
  
        // Voice adını belirle
        const voice = this.getVoiceNameForLanguage(languageCode);
        console.log('Using voice:', voice);
        
        // SSML formatı
        const ssml = `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="${languageCode}"><voice name="${voice}">${this.escapeXml(text)}</voice></speak>`;
  
        // API endpoint'i
        const endpoint = `https://${this.region}.tts.speech.microsoft.com/cognitiveservices/v1`;
        
        // API isteği yap
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': this.subscriptionKey,
            'Content-Type': 'application/ssml+xml',
            'X-Microsoft-OutputFormat': 'audio-16khz-128kbitrate-mono-mp3'
          },
          body: ssml
        });
  
        if (!response.ok) {
          const errorText = await response.text();
          console.error(`TTS API error (${response.status}):`, errorText);
          throw new Error(`TTS API error (${response.status}): ${errorText}`);
        }
  
        // Ses verisi alındı
        const arrayBuffer = await response.arrayBuffer();
        console.log('Received audio data of size:', arrayBuffer.byteLength, 'bytes');
        
        if (arrayBuffer.byteLength === 0) {
          console.error('Received empty audio data');
          if (onError) onError('Boş ses verisi alındı');
          return;
        }
        
        // Blob oluştur
        const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
        
        // Ses indirme düğmesi oluştur ya da güncelle
        this.createOrUpdateAudioElement(blob, text, onCompleted);
        
        // Tamamlandı
        if (onCompleted) onCompleted();
        
      } catch (error) {
        console.error('TTS error:', error);
        if (onError) onError(`TTS hatası: ${error instanceof Error ? error.message : String(error)}`);
      }
    }
    
    /**
     * Ses için bir HTML düğmesi oluşturur veya günceller
     */
    private createOrUpdateAudioElement(blob: Blob, text: string, onCompleted?: () => void): void {
      // Eski butonu temizle
      if (this.speakButton && this.speakButton.parentNode) {
        this.speakButton.remove();
      }
      
      try {
        // Yeni bir URL oluştur - ama data: URL değil, blob: URL kullan
        const url = URL.createObjectURL(blob);
        
        // Ses dosyası için isim oluştur
        const fileName = `speech_${new Date().getTime()}.mp3`;
        
        // Butonu oluştur
        this.speakButton = document.createElement('button');
        this.speakButton.innerHTML = 'Sesi Çal';
        this.speakButton.className = 'btn btn-sm btn-success mt-2';
        this.speakButton.style.display = 'block';
        
        // Butona tıklandığında
        this.speakButton.onclick = (event) => {
          event.preventDefault();
          
          // Audio elementi oluştur
          const audio = new Audio(url);
          
          // Ses çalmayı başlat
          audio.play()
            .then(() => {
              console.log('Audio playing');
            })
            .catch(error => {
              console.error('Play error:', error);
              
              // Ses çalınamazsa, indirme bağlantısı oluştur
              if (error.name === 'NotAllowedError' || error.name === 'NotSupportedError') {
                console.warn('Audio play requires user interaction or not supported, creating download link');
                this.createDownloadLink(blob, fileName, text);
              }
            });
        };
        
        // Butonu sayfaya ekle
        const containerEl = document.querySelector('.tts-controls') || document.body;
        containerEl.appendChild(this.speakButton);
      } catch (error) {
        console.error('Error creating audio button:', error);
        // Son çare: İndirme bağlantısı oluştur
        this.createDownloadLink(blob, `speech_${new Date().getTime()}.mp3`, text);
      }
    }
    
    /**
     * Ses dosyasını indirmek için bağlantı oluşturur
     */
    private createDownloadLink(blob: Blob, fileName: string, text: string): void {
      try {
        // Eski butonu temizle
        if (this.speakButton && this.speakButton.parentNode) {
          this.speakButton.remove();
        }
        
        // URL oluştur
        const url = URL.createObjectURL(blob);
        
        // İndirme bağlantısı oluştur
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.innerHTML = 'Sesi İndir';
        link.className = 'btn btn-sm btn-primary mt-2';
        link.style.display = 'block';
        
        // Bağlantıyı sayfaya ekle
        const containerEl = document.querySelector('.tts-controls') || document.body;
        containerEl.appendChild(link);
        
        // Referansı sakla
        this.speakButton = link as any;
      } catch (error) {
        console.error('Error creating download link:', error);
      }
    }
    
    /**
     * Seslendirmeyi durdurur
     */
    public stopSpeaking(): void {
      // Tüm audio elementlerini durdur
      document.querySelectorAll('audio').forEach(audio => {
        audio.pause();
        audio.currentTime = 0;
      });
    }
    
    /**
     * XML için tehlikeli karakterleri escape eder
     */
    private escapeXml(text: string): string {
      return text
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&apos;');
    }
    
    /**
     * Dil koduna göre uygun ses adını döndürür
     */
    private getVoiceNameForLanguage(languageCode: string): string {
      // Dillere göre sesler
      const voices: Record<string, string> = {
        'en-US': 'en-US-JennyNeural',
        'en-GB': 'en-GB-SoniaNeural',
        'tr-TR': 'tr-TR-EmelNeural',
        'es-ES': 'es-ES-ElviraNeural',
        'fr-FR': 'fr-FR-DeniseNeural',
        'de-DE': 'de-DE-KatjaNeural',
        'it-IT': 'it-IT-ElsaNeural',
        'ja-JP': 'ja-JP-NanamiNeural',
        'ko-KR': 'ko-KR-SunHiNeural',
        'zh-CN': 'zh-CN-XiaoxiaoNeural',
        'ru-RU': 'ru-RU-SvetlanaNeural'
      };
      
      // Dil kodu için özel bir ses varsa onu döndür, yoksa dil kodunu kullan
      return voices[languageCode] || voices[languageCode.split('-')[0]] || languageCode;
    }
  }
  
  export default AzureTextToSpeechService;