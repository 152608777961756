// src/services/AzureSpeechService.ts
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import { SpeechService } from './SpeechServiceInterfaces';

class AzureSpeechService implements SpeechService {
  private subscriptionKey: string;
  private region: string;
  private recognizer: SpeechSDK.SpeechRecognizer | null = null;
  private translator: SpeechSDK.TranslationRecognizer | null = null;
  // Add translator API key and region
  private translatorKey: string;
  private translatorRegion: string;

  constructor(subscriptionKey: string, region: string, translatorKey?: string, translatorRegion?: string) {
    this.subscriptionKey = subscriptionKey;
    this.region = region;
    // Use the same key and region for translator if not provided separately
    this.translatorKey = translatorKey || subscriptionKey;
    this.translatorRegion = translatorRegion || region;
    
    console.log('AzureSpeechService initialized with:', {
      region,
      translatorRegion: this.translatorRegion
    });
  }

  public startSpeechRecognition(
    sourceLanguage: string,
    targetLanguage: string,
    onRecognized: (text: string) => void,
    onTranslated: (text: string) => void,
    onError: (error: string) => void
  ): boolean {
    try {
      // Log languages for debugging
      console.log('Speech Recognition started with:', {
        sourceLanguage,
        targetLanguage
      });
      
      // Stop any existing recognizers first
      this.stopSpeechRecognition();

      // Create speech configuration
      const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
        this.subscriptionKey,
        this.region
      );

      // Set source language
      speechConfig.speechRecognitionLanguage = sourceLanguage;
      
      // Create audio configuration using default microphone
      const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();

      // Create speech recognizer
      this.recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
      
      // Set up event handlers for recognition
      this.recognizer.recognized = (s, e) => {
        if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
          const recognizedText = e.result.text;
          console.log('Speech recognized:', recognizedText);
          onRecognized(recognizedText);
          
          // Instead of using the Speech SDK for translation, use the Translator Text API
          if (recognizedText) {
            this.translateText(recognizedText, sourceLanguage, targetLanguage)
              .then(translatedText => {
                console.log('Translation result:', translatedText);
                onTranslated(translatedText);
              })
              .catch(error => {
                console.error("Translation error:", error);
                // Fall back to a basic translation indicator if API fails
                onTranslated(`[${targetLanguage.split('-')[0].toUpperCase()}] ${recognizedText}`);
                onError(`Translation error: ${error.message || error}`);
              });
          }
        } else if (e.result.reason === SpeechSDK.ResultReason.NoMatch) {
          onError("Speech could not be recognized.");
        }
      };

      // Handle errors
      this.recognizer.canceled = (s, e) => {
        if (e.reason === SpeechSDK.CancellationReason.Error) {
          console.error('Recognition canceled due to error:', e.errorDetails);
          onError(`Error: ${e.errorDetails}`);
        }
      };

      // Start continuous recognition
      this.recognizer.startContinuousRecognitionAsync(
        () => {
          console.log("Azure Speech recognition started successfully");
        },
        (err) => {
          console.error("Error starting recognition:", err);
          onError(`Failed to start recognition: ${err}`);
          return false;
        }
      );

      return true;
    } catch (error) {
      console.error('Exception in startSpeechRecognition:', error);
      onError(`Failed to start speech recognition: ${error instanceof Error ? error.message : String(error)}`);
      return false;
    }
  }

  private async translateText(text: string, fromLang: string, toLang: string): Promise<string> {
    if (!text?.trim()) return '';
    
    try {
      // Convert the language codes to format required by Translator API (e.g., en-US -> en)
      // Add defensive code to handle unexpected language code formats
      let fromLangCode = 'en'; // Default fallback
      let toLangCode = 'tr';   // Default fallback
      
      try {
        if (fromLang && fromLang.includes('-')) {
          fromLangCode = fromLang.split('-')[0];
        } else if (fromLang) {
          fromLangCode = fromLang;
        }
        
        if (toLang && toLang.includes('-')) {
          toLangCode = toLang.split('-')[0];
        } else if (toLang) {
          toLangCode = toLang;
        }
      } catch (parseError) {
        console.error('Error parsing language codes:', parseError);
      }
      
      console.log('Starting translation:', { 
        text, 
        fromLang,
        toLang,
        fromLangCode, 
        toLangCode 
      });
      
      // Construct URL with proper endpoint - IMPORTANT: Use the correct URL format
      const url = `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=${fromLangCode}&to=${toLangCode}`;

      console.log('Request URL:', url);
      
      const requestBody = [{ Text: text }];
      console.log('Request Body:', JSON.stringify(requestBody));
      
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Ocp-Apim-Subscription-Key': this.translatorKey,
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Region': this.translatorRegion
        },
        body: JSON.stringify(requestBody)
      });

      console.log('Translation API response status:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Translation API error (${response.status}):`, errorText);
        throw new Error(`Translation API error (${response.status}): ${errorText}`);
      }

      const data = await response.json();
      console.log('Translation API response data:', data);
      
      // Validate the response structure before accessing properties
      if (data && Array.isArray(data) && data.length > 0 && 
          data[0].translations && Array.isArray(data[0].translations) && 
          data[0].translations.length > 0) {
        return data[0].translations[0].text || 'Translation failed';
      } else {
        console.error('Unexpected API response structure:', data);
        return 'Translation failed: Invalid API response';
      }
    } catch (error) {
      console.warn('Translation error:', error);
      throw error;
    }
  }

  public stopSpeechRecognition(): void {
    console.log('Stopping speech recognition');
    
    // Stop and clean up the speech recognizer
    if (this.recognizer) {
      try {
        this.recognizer.stopContinuousRecognitionAsync(
          () => {
            console.log('Speech recognizer stopped successfully');
            this.recognizer?.close();
            this.recognizer = null;
          },
          (err) => {
            console.error("Error stopping recognizer:", err);
          }
        );
      } catch (error) {
        console.error("Error in stopSpeechRecognition (recognizer):", error);
      }
    }

    // Clean up translator if needed
    if (this.translator) {
      try {
        this.translator.stopContinuousRecognitionAsync(
          () => {
            console.log('Translator stopped successfully');
            this.translator?.close();
            this.translator = null;
          },
          (err) => {
            console.error("Error stopping translator:", err);
          }
        );
      } catch (error) {
        console.error("Error in stopSpeechRecognition (translator):", error);
      }
    }
  }
}

export default AzureSpeechService;