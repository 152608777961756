// src/components/Navigation.tsx
import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';

// Not: react-icons importlarını kaldırdık
// Bunun yerine bootstrap-icons CSS'ini App.tsx veya index.js'ye ekleyin
// import 'bootstrap-icons/font/bootstrap-icons.css';

const Navigation = () => {
  const { t, setLanguage } = useLanguage();

  return (
    <Navbar bg="white" variant="light" expand="lg" sticky="top" className="shadow-sm">
      <Container>
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <img 
            src="/logo.png"
            alt="CSC Voice AI"
            height="32"
            className="me-2"
          />
          <span className="brand-text">CSC Voice AI</span>
        </Navbar.Brand>
        {/* Boş bırakılan Toggle düğmesi - Bootstrap kendi ikonunu kullanacak */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" className="nav-item">
              <i className="bi bi-house nav-icon"></i>
              <span>{t('nav.home')}</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/about" className="nav-item">
              <i className="bi bi-info-circle nav-icon"></i>
              <span>{t('nav.about')}</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/packages" className="nav-item">
              <i className="bi bi-box nav-icon"></i>
              <span>{t('nav.packages')}</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/contact" className="nav-item">
              <i className="bi bi-envelope nav-icon"></i>
              <span>{t('nav.contact')}</span>
            </Nav.Link>
            
            {/* Legal Dropdown */}
            <NavDropdown 
              title={
                <div className="d-inline-flex align-items-center">
                  <i className="bi bi-file-text nav-icon"></i>
                  <span>{t('nav.legal')}</span>
                </div>
              }
              id="legal-nav-dropdown"
              className="nav-item"
            >
              <NavDropdown.Item as={Link} to="/terms" className="dropdown-item">
                <i className="bi bi-file-text nav-icon"></i>
                {t('nav.terms')}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/privacy" className="dropdown-item">
                <i className="bi bi-shield nav-icon"></i>
                {t('nav.privacy')}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          
          <Nav>
            <NavDropdown 
              title={
                <div className="d-inline-flex align-items-center">
                  <i className="bi bi-globe nav-icon"></i>
                  <span>{t('nav.language')}</span>
                </div>
              } 
              id="language-nav-dropdown"
              className="nav-item"
            >
              <NavDropdown.Item onClick={() => setLanguage('tr')}>Türkçe</NavDropdown.Item>
              <NavDropdown.Item onClick={() => setLanguage('en')}>English</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;