// src/pages/About.tsx
import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { useLanguage } from '../context/LanguageContext';
import { usePixabay } from '../hooks/usePixabay';
import { FiAward, FiUsers } from 'react-icons/fi';
import Icon from '../components/Icon'; // Yolunu ayarlayın.

const About = () => {
  const { t } = useLanguage();
  const { imageUrl: heroImage, loading: heroLoading } = usePixabay('modern office technology', 'horizontal');
  const { imageUrl: missionImage, loading: missionLoading } = usePixabay('team meeting technology', 'horizontal');
  const { imageUrl: visionImage, loading: visionLoading } = usePixabay('future technology', 'horizontal');

  const stats = [
    {
      iconComponent: FiUsers, // İkon bileşenini doğrudan saklayın, render etmeyin.
      iconSize: 40,
      number: "about.statnumber1",
      label: "about.activeUsers"
    },
    {
      icon: 40,
      number: "about.statnumber2",
      label: "about.supportedLanguages"  // Dil dosyasındaki anahtarı kullanıyoruz
    },
    {
      icon: 40,
      number: "about.statnumber3",
      label: "about.customerSatisfaction"  // Dil dosyasındaki anahtarı kullanıyoruz
    }
  ];

  return (
    <div className="container-fluid-custom">
      {/* Hero Section */}
      <div 
        className="about-hero"
        style={{
          backgroundImage: heroImage ? `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${heroImage})` : undefined,
        }}
      >
        {heroLoading ? (
          <Container className="text-center py-5">
            <Spinner animation="border" variant="light" />
          </Container>
        ) : (
          <Container className="text-center text-white py-5">
            <h1 className="display-4 mb-3">{t('about.title')}</h1>
            <p className="lead fs-lg">{t('about.subtitle')}</p>
          </Container>
        )}
      </div>

     {/* Stats Section */}
      <div className="py-5 bg-light">
        <Container>
          <Row className="justify-content-center">
            {stats.map((stat, index) => (
              <Col key={index} md={4} className="text-center mb-4 mb-md-0">
                <div className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle p-4 mb-3 text-white">
                  {stat.icon}
                </div>
                <h2 className="display-4 fw-bold mb-1">{t(stat.number)}</h2>
                <p className="text-muted mb-0">{t(stat.label)}</p>  {/* Dil değişimine uyumlu hale getirildi */}
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      {/* Mission Section */}
      <div className="content-section">
        <Row className="g-0">
          <Col md={6}>
            <div className="image-section">
              {missionLoading ? (
                <div className="d-flex align-items-center justify-content-center h-100">
                  <Spinner animation="border" />
                </div>
              ) : (
                <img 
                  src={missionImage || ''}
                  alt={t('about.mission')}
                  className="w-100 h-100 object-fit-cover"
                />
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="text-content">
              <h2 className="display-5 mb-4">{t('about.mission')}</h2>
              <p className="lead mb-3">{t('about.missionText')}</p>
              <p className="text-muted">{t('about.missionDescription')}</p>
            </div>
          </Col>
        </Row>
      </div>

      {/* Vision Section */}
      <div className="content-section-alt">
        <Row className="g-0">
          <Col md={6} className="order-md-2">
            <div className="image-section">
              {visionLoading ? (
                <div className="d-flex align-items-center justify-content-center h-100">
                  <Spinner animation="border" />
                </div>
              ) : (
                <img 
                  src={visionImage || ''}
                  alt="Our Vision"
                  className="w-100 h-100 object-fit-cover"
                />
              )}
            </div>
          </Col>
          <Col md={6} className="order-md-1">
            <div className="text-content">
              <h2 className="display-5 mb-4">{t('about.vizyon')}</h2>
              <p className="lead mb-4">{t('about.vizyonDescription')}</p>
            </div>
          </Col>
        </Row>
      </div>


      {/* Values Section */}
      <div className="content-section-alt text-center">
        <Container>
          <h2 className="display-5 mb-5">{t('about.deger')}</h2>
          <Row className="g-4">
            <Col md={4}>
            <div className="value-card p-4">
              <div className="value-icon mb-3">
                <Icon icon={FiAward} size={40} className="text-primary" />
              </div>
              <h3 className="h4 mb-3">{t('about.deger1')}</h3>
              <p className="text-muted">{t('about.deger2')}</p>
            </div>
            </Col>
            <Col md={4}>
              <div className="value-card p-4">
              <div className="value-icon mb-3">
                <i className="bi bi-people text-primary" style={{ fontSize: '40px' }}></i>
              </div>
                <h3 className="h4 mb-3">{t('about.isbir1')}</h3>
                <p className="text-muted">{t('about.isbir2')}</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="value-card p-4">
                <div className="value-icon mb-3">
                <i className="bi bi-globe text-primary" style={{ fontSize: '40px' }}></i>
                </div>
                <h3 className="h4 mb-3">{t('about.glog1')}</h3>
                <p className="text-muted">{t('about.glog1')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};


export default About;