import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { LanguageProvider } from './context/LanguageContext';
import MainApp from './MainApp';
import './styles/App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
  return (
    <LanguageProvider>
      <BrowserRouter>
        <MainApp />
      </BrowserRouter>
    </LanguageProvider>
  );
}

export default App;