// src/utils/BrowserSupportCheck.ts
// Tarayıcının konuşma tanıma ve çeviri özelliklerini destekleyip desteklemediğini kontrol eden yardımcı fonksiyonlar

/**
 * Tarayıcının SpeechRecognition API'sini destekleyip desteklemediğini kontrol eder
 */
export const isSpeechRecognitionSupported = (): boolean => {
  return 'SpeechRecognition' in window || 
         'webkitSpeechRecognition' in window || 
         'mozSpeechRecognition' in window || 
         'msSpeechRecognition' in window;
};

/**
 * Tarayıcının mikrofon iznine erişip erişemeyeceğini kontrol eder
 */
export const checkMicrophoneAccess = async (): Promise<{
  hasAccess: boolean; 
  error?: string;
}> => {
  try {
    // Önce kullanılabilir cihazları kontrol et
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioInputDevices = devices.filter(device => device.kind === 'audioinput');
    
    if (audioInputDevices.length === 0) {
      console.warn('Sistemde mikrofon bulunamadı');
      return { 
        hasAccess: false, 
        error: 'Sistemde mikrofon bulunamadı. Lütfen bir mikrofon bağlayıp tekrar deneyin.' 
      };
    }
    
    // Mikrofon erişimi isteği
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    
    // İzin verildi, stream'i temizle
    stream.getTracks().forEach(track => track.stop());
    
    return { hasAccess: true };
  } catch (error) {
    console.error('Mikrofon erişimi reddedildi:', error);
    
    let errorMessage = 'Mikrofon erişimi reddedildi.';
    
    // Daha spesifik hata mesajları için
    const err = error as Error;
    if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
      errorMessage = 'Mikrofon erişimi reddedildi. Lütfen tarayıcı izinlerini kontrol edin.';
    } else if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
      errorMessage = 'Mikrofon bulunamadı. Lütfen bir mikrofon bağlayın.';
    } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
      errorMessage = 'Mikrofon kullanılamıyor. Başka bir uygulama mikrofonu kullanıyor olabilir.';
    } else if (err.name === 'OverconstrainedError') {
      errorMessage = 'Mikrofon gereksinimleri karşılamıyor.';
    } else if (err.name === 'TypeError') {
      errorMessage = 'Mikrofon erişimi için geçersiz parametreler.';
    }
    
    return { hasAccess: false, error: errorMessage };
  }
};

/**
 * Kullanıcının tarayıcısının konuşma tanıma için uygun olup olmadığını kontrol eder
 * ve uygun bir hata mesajı döndürür
 */
export const checkBrowserCompatibility = async (): Promise<{
  isCompatible: boolean;
  errorMessage: string;
}> => {
  // HTTPS kontrolü (localhost hariç)
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  const isSecure = window.location.protocol === 'https:' || isLocalhost;

  if (!isSecure) {
    return {
      isCompatible: false,
      errorMessage: 'Konuşma tanıma özelliği sadece HTTPS üzerinde çalışır. Lütfen güvenli bir bağlantı kullanın.'
    };
  }

  // SpeechRecognition API desteği kontrolü
  if (!isSpeechRecognitionSupported()) {
    return {
      isCompatible: false,
      errorMessage: 'Tarayıcınız konuşma tanıma API\'sini desteklemiyor. Lütfen Chrome, Edge, Safari veya Firefox kullanın.'
    };
  }

  // MediaDevices API desteği kontrolü
  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    return {
      isCompatible: false,
      errorMessage: 'Tarayıcınız mikrofon erişimi için gereken API\'leri desteklemiyor.'
    };
  }

  // Mikrofon erişim kontrolü yapmıyoruz - kullanıcı tıkladığında kontrol edeceğiz
  
  // Tüm kontroller başarılı
  return {
    isCompatible: true,
    errorMessage: ''
  };
};

/**
 * Tarayıcılara göre konuşma tanıma kalitesi tahmini
 */
export const getBrowserSpeechQuality = (): {
  browser: string;
  quality: 'excellent' | 'good' | 'fair' | 'poor' | 'unsupported';
  notes: string;
} => {
  const userAgent = navigator.userAgent.toLowerCase();
  
  // Chrome veya Chrome tabanlı tarayıcılar
  if (userAgent.includes('chrome')) {
    return {
      browser: 'Chrome/Chrome tabanlı',
      quality: 'excellent',
      notes: 'Chrome, Web Speech API için en iyi desteği sunar.'
    };
  }
  
  // Microsoft Edge (Chromium tabanlı)
  else if (userAgent.includes('edg')) {
    return {
      browser: 'Microsoft Edge',
      quality: 'excellent',
      notes: 'Edge, Chromium tabanlı olduğu için konuşma tanıma desteği mükemmeldir.'
    };
  }
  
  // Safari
  else if (userAgent.includes('safari') && !userAgent.includes('chrome')) {
    return {
      browser: 'Safari',
      quality: 'good',
      notes: 'Safari\'nin konuşma tanıma desteği iyidir, ancak bazı gelişmiş özellikler eksik olabilir.'
    };
  }
  
  // Firefox
  else if (userAgent.includes('firefox')) {
    return {
      browser: 'Firefox',
      quality: 'fair',
      notes: 'Firefox\'un Web Speech API desteği sınırlıdır ve performans değişkenlik gösterebilir.'
    };
  }
  
  // Diğer tarayıcılar
  return {
    browser: 'Bilinmeyen/Diğer',
    quality: 'poor',
    notes: 'Tarayıcınız konuşma tanıma için optimal değil. Chrome, Edge veya Safari kullanmanızı öneririz.'
  };
};