// src/services/BrowserSpeechService.ts
import { SpeechService } from './SpeechServiceInterfaces';

class BrowserSpeechService implements SpeechService {
  private recognition: any = null;
  private isListening: boolean = false;
  private sourceLanguage: string = 'en-US';
  private targetLanguage: string = 'tr-TR';
  private onRecognizedCallback: ((text: string) => void) | null = null;
  private onTranslatedCallback: ((text: string) => void) | null = null;
  private onErrorCallback: ((error: string) => void) | null = null;
  private recognitionTimeout: number | null = null;
  private lastRecognizedText: string = '';
  // Optional translator key and region for improved translations
  private translatorKey: string | null = null;
  private translatorRegion: string | null = null;

  constructor(translatorKey?: string, translatorRegion?: string) {
    // Store translator credentials if provided
    this.translatorKey = translatorKey || null;
    this.translatorRegion = translatorRegion || null;
    
    console.log('BrowserSpeechService initialized with translator:', {
      hasTranslatorKey: !!this.translatorKey,
      translatorRegion: this.translatorRegion
    });
    
    // Check if browser supports Speech Recognition
    if ('webkitSpeechRecognition' in window) {
      // @ts-ignore - webkitSpeechRecognition is not in TypeScript types
      this.recognition = new (window as any).webkitSpeechRecognition();
      console.log('Using webkitSpeechRecognition');
    } else if ('SpeechRecognition' in window) {
      // @ts-ignore - SpeechRecognition might not be in TS types
      this.recognition = new (window as any).SpeechRecognition();
      console.log('Using SpeechRecognition');
    } else {
      console.warn('Speech recognition not supported in this browser');
    }

    if (this.recognition) {
      this.setupRecognition();
    }
  }

  private setupRecognition(): void {
    if (!this.recognition) return;

    // Set to non-continuous and configure options
    this.recognition.continuous = false;
    this.recognition.interimResults = false; // Change to false to only get final results
    this.recognition.maxAlternatives = 1;
    
    this.recognition.onresult = (event: any) => {
      if (!this.isListening) return;
      
      let finalTranscript = '';
      
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript;
        }
      }
      
      if (finalTranscript && this.onRecognizedCallback) {
        console.log('Browser recognized text:', finalTranscript);
        this.lastRecognizedText = finalTranscript;
        this.onRecognizedCallback(finalTranscript);
        
        // Always call translateText with the recognized text
        this.translateText(finalTranscript);
      }
    };
    
    this.recognition.onerror = (event: any) => {
      console.log("Speech recognition error:", event.error);
      
      // Don't report "no-speech" as an error to the user, it's common
      if (event.error !== 'no-speech' && this.onErrorCallback) {
        // For network errors, suggest checking internet connection
        if (event.error === 'network') {
          this.onErrorCallback(`Recognition error: network. Please check your internet connection.`);
        } else {
          this.onErrorCallback(`Recognition error: ${event.error}. Please check your microphone and try again.`);
        }
      }
      
      // Attempt to restart for some errors
      if (['network', 'aborted'].includes(event.error)) {
        this.safelyRestartRecognition();
      }
    };
    
    this.recognition.onend = () => {
      console.log("Speech recognition ended");
      
      // Only try to restart if we're still supposed to be listening
      if (this.isListening) {
        this.safelyRestartRecognition();
      }
    };
  }

  // Safely restart recognition with a delay
  private safelyRestartRecognition(): void {
    if (!this.isListening) return;
    
    // Clear any existing timeout
    if (this.recognitionTimeout !== null) {
      window.clearTimeout(this.recognitionTimeout);
      this.recognitionTimeout = null;
    }
    
    // Add a delay before restarting
    this.recognitionTimeout = window.setTimeout(() => {
      try {
        if (this.isListening && this.recognition) {
          console.log("Restarting speech recognition...");
          this.recognition.start();
        }
      } catch (error) {
        console.error("Error restarting recognition:", error);
        this.isListening = false;
        if (this.onErrorCallback) {
          this.onErrorCallback(`Failed to restart recognition. Please refresh the page and try again.`);
        }
      }
    }, 500); // Longer delay (500ms) to prevent "already started" errors
  }

  public startSpeechRecognition(
    sourceLanguage: string,
    targetLanguage: string,
    onRecognized: (text: string) => void,
    onTranslated: (text: string) => void,
    onError: (error: string) => void
  ): boolean {
    if (!this.recognition) {
      onError('Speech recognition not supported in this browser');
      return false;
    }
    
    console.log('Browser speech recognition starting with:', {
      sourceLanguage,
      targetLanguage
    });
    
    // Reset the last recognized text
    this.lastRecognizedText = '';
    
    // Store callbacks
    this.sourceLanguage = sourceLanguage;
    this.targetLanguage = targetLanguage;
    this.onRecognizedCallback = onRecognized;
    this.onTranslatedCallback = onTranslated;
    this.onErrorCallback = onError;
    
    // Make sure we're stopped before starting
    this.stopSpeechRecognition();
    
    // Add a delay before trying to start
    setTimeout(() => {
      try {
        this.recognition.lang = sourceLanguage;
        this.recognition.start();
        this.isListening = true;
        console.log('Browser speech recognition started');
      } catch (error) {
        console.error("Error starting speech recognition:", error);
        onError(`Failed to start speech recognition: ${error instanceof Error ? error.message : String(error)}`);
      }
    }, 500);
    
    return true;
  }

  public stopSpeechRecognition(): void {
    console.log('Stopping browser speech recognition');
    
    // Clear any pending restarts
    if (this.recognitionTimeout !== null) {
      window.clearTimeout(this.recognitionTimeout);
      this.recognitionTimeout = null;
    }
    
    this.isListening = false;
    
    // Always try to translate the last recognized text when stopping
    if (this.lastRecognizedText && this.onTranslatedCallback) {
      this.translateText(this.lastRecognizedText);
    }
    
    if (this.recognition) {
      try {
        this.recognition.stop();
        console.log('Browser speech recognition stopped');
      } catch (error) {
        console.error("Error stopping recognition:", error);
        // If we can't stop it, try to create a new instance
        try {
          if ('webkitSpeechRecognition' in window) {
            // @ts-ignore
            this.recognition = new (window as any).webkitSpeechRecognition();
            this.setupRecognition();
            console.log('Recognition instance recreated');
          }
        } catch (reinitError) {
          console.error("Error reinitializing recognition:", reinitError);
        }
      }
    }
  }

  private async translateText(text: string): Promise<void> {
    if (!text || !this.onTranslatedCallback) return;
    
    try {
      console.log('Attempting to translate text:', text);
      console.log('From:', this.sourceLanguage, 'To:', this.targetLanguage);
      
      // First check if we can use Azure Translator API
      if (this.translatorKey && this.translatorRegion) {
        try {
          // Extract language codes safely
          let fromLangCode = 'en'; // Default fallback
          let toLangCode = 'tr';   // Default fallback
          
          try {
            if (this.sourceLanguage && this.sourceLanguage.includes('-')) {
              fromLangCode = this.sourceLanguage.split('-')[0];
            } else if (this.sourceLanguage) {
              fromLangCode = this.sourceLanguage;
            }
            
            if (this.targetLanguage && this.targetLanguage.includes('-')) {
              toLangCode = this.targetLanguage.split('-')[0];
            } else if (this.targetLanguage) {
              toLangCode = this.targetLanguage;
            }
          } catch (parseError) {
            console.error('Error parsing language codes:', parseError);
          }
          
          console.log('Using Azure Translator with langs:', { fromLangCode, toLangCode });
          
          // IMPORTANT: Use the correct translator URL format
          const url = `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=${fromLangCode}&to=${toLangCode}`;

          console.log('Translation API request URL:', url);
          
          const requestBody = [{ Text: text }];
          console.log('Translation API request body:', JSON.stringify(requestBody));
          
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Ocp-Apim-Subscription-Key': this.translatorKey,
              'Content-Type': 'application/json',
              'Ocp-Apim-Subscription-Region': this.translatorRegion
            },
            body: JSON.stringify(requestBody)
          });

          console.log('Translation API response status:', response.status);
          
          if (response.ok) {
            const data = await response.json();
            console.log('Translation API response data:', data);
            
            // Validate response structure
            if (data && Array.isArray(data) && data.length > 0 && 
                data[0].translations && Array.isArray(data[0].translations) && 
                data[0].translations.length > 0) {
              
              const translatedText = data[0].translations[0].text;
              console.log('Translated text from API:', translatedText);
              
              if (translatedText && this.onTranslatedCallback) {
                this.onTranslatedCallback(translatedText);
                return;
              }
            } else {
              console.error('Unexpected API response structure:', data);
            }
          } else {
            const errorText = await response.text();
            console.error(`Translation API error (${response.status}):`, errorText);
          }
          
          // If we reach here, something went wrong with the API call
          console.warn('Translator API failed, falling back to basic translation');
        } catch (apiError) {
          console.error('Error using Translator API:', apiError);
          // Continue to fallback translation
        }
      }
      
      // Fallback to basic dictionary-based translation
      console.log('Using basic dictionary translation');
      
      // Get basic translations from this map
      const translations: Record<string, Record<string, string>> = {
        'en': {
          'hello': 'merhaba',
          'good morning': 'günaydın', 
          'how are you': 'nasılsın',
          'thank you': 'teşekkür ederim',
          'what is your name': 'adın ne',
          'my name is': 'benim adım',
          'welcome': 'hoş geldiniz',
          'goodbye': 'hoşça kal',
          'yes': 'evet',
          'no': 'hayır',
          'please': 'lütfen',
          'excuse me': 'afedersiniz',
          'i don\'t understand': 'anlamıyorum',
          'speak slowly': 'yavaş konuş',
          'repeat please': 'tekrar edin lütfen',
          'where is': 'nerede',
          'when': 'ne zaman',
          'why': 'neden',
          'who': 'kim',
          'what': 'ne',
          'how': 'nasıl',
          'airport': 'havalimanı',
          'hotel': 'otel',
          'restaurant': 'restoran',
          'taxi': 'taksi',
          'bus': 'otobüs',
          'train': 'tren',
          'welcome to our meeting': 'toplantımıza hoş geldiniz',
          'your participation is important to us': 'katılımınız bizim için önemli',
          'today': 'bugün',
          'everything': 'her şey',
          'is': 'dir',
          'in order': 'yolunda',
          'today everything is in order': 'bugün her şey yolunda',
          'welcome to our bank': 'bankamıza hoş geldiniz',
          'we are very happy to have you here today': 'bugün burada olmanız bizi çok mutlu etti'
        },
        'tr': {
          'merhaba': 'hello',
          'günaydın': 'good morning',
          'nasılsın': 'how are you',
          'teşekkür ederim': 'thank you',
          'adın ne': 'what is your name',
          'benim adım': 'my name is',
          'hoş geldiniz': 'welcome',
          'hoşça kal': 'goodbye',
          'evet': 'yes',
          'hayır': 'no',
          'lütfen': 'please',
          'afedersiniz': 'excuse me',
          'anlamıyorum': 'i don\'t understand',
          'yavaş konuş': 'speak slowly',
          'tekrar edin lütfen': 'repeat please',
          'nerede': 'where is',
          'ne zaman': 'when',
          'neden': 'why',
          'kim': 'who',
          'ne': 'what',
          'nasıl': 'how',
          'havalimanı': 'airport',
          'otel': 'hotel',
          'restoran': 'restaurant',
          'taksi': 'taxi',
          'otobüs': 'bus',
          'tren': 'train',
          'toplantımıza hoş geldiniz': 'welcome to our meeting',
          'katılımınız bizim için önemli': 'your participation is important to us',
          'bugün': 'today',
          'her şey': 'everything',
          'dir': 'is',
          'yolunda': 'in order',
          'bugün her şey yolunda': 'today everything is in order',
          'bugün her şey yolunda mı': 'is everything in order today',
          'hoş geldiniz bugün her şey yolunda mı': 'welcome is everything in order today',
          'toplantımıza hoş geldiniz ve katılımınız bizim için çok çok önemli': 'welcome to our meeting and your participation is very very important to us',
          'bankamıza hoş geldiniz': 'welcome to our bank',
          'bugün burada olmanız bizi çok mutlu etti': 'we are very happy to have you here today'
        }
      };
      
      // Determine source and target languages based on UI selections
      let fromLang = 'en';
      let toLang = 'tr';
      
      // Parse source language correctly
      if (this.sourceLanguage.toLowerCase().includes('en')) {
        fromLang = 'en';
      } else if (this.sourceLanguage.toLowerCase().includes('tr')) {
        fromLang = 'tr';
      }
      
      // Parse target language correctly
      if (this.targetLanguage.toLowerCase().includes('en')) {
        toLang = 'en';
      } else if (this.targetLanguage.toLowerCase().includes('tr')) {
        toLang = 'tr';
      }
      
      console.log('Dictionary translation:', { fromLang, toLang });
      
      // Check if we can do a direct translation from our basic dictionary
      const lowerText = text.toLowerCase();
      let translatedText = '';
      
      if (translations[fromLang] && translations[fromLang][lowerText]) {
        translatedText = translations[fromLang][lowerText];
        console.log('Found direct match in dictionary:', translatedText);
      } else {
        // Otherwise, just mock translation by adding a prefix
        translatedText = `[${toLang.toUpperCase()}] ${text}`;
        console.log('No dictionary match, using prefix:', translatedText);
      }
      
      // Simulate network delay for realism
      setTimeout(() => {
        if (this.onTranslatedCallback) {
          console.log('Returning translation result:', translatedText);
          this.onTranslatedCallback(translatedText);
        }
      }, 700);
    } catch (error) {
      console.error("Translation error:", error);
      if (this.onErrorCallback) {
        this.onErrorCallback(`Translation error: ${error instanceof Error ? error.message : String(error)}`);
      }
    }
  }
}

export default BrowserSpeechService;