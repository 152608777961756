// src/utils/TimeLimiter.ts

/**
 * Basit bir zaman sınırlayıcı
 * LocalStorage kullanarak kullanıcıya belirli bir süre erişim izni verir
 * Her resetDurationMinutes dakikada bir süre sıfırlanır
 */
class TimeLimiter {
    private readonly storageKey: string;
    private readonly testDurationMs: number;
    private readonly resetDurationMs: number;
  
    /**
     * @param testDurationMinutes - İzin verilen test süresi (dakika olarak)
     * @param resetDurationMinutes - Test süresinin sıfırlanma süresi (dakika olarak)
     * @param storageKey - localStorage'da kullanılacak anahtar
     */
    constructor(
      testDurationMinutes: number = 5, 
      resetDurationMinutes: number = 30,
      storageKey: string = 'test_time_limit'
    ) {
      this.testDurationMs = testDurationMinutes * 60 * 1000;
      this.resetDurationMs = resetDurationMinutes * 60 * 1000;
      this.storageKey = storageKey;
    }
  
    /**
     * Kullanıcının şu anda test yapıp yapamayacağını kontrol eder
     * @returns İzin verilip verilmediği bilgisini içeren nesne
     */
    public checkLimit(): { 
      allowed: boolean; 
      message?: string; 
      remainingTimeMs?: number;
      nextResetTimeMs?: number;
    } {
      const now = Date.now();
      
      // localStorage'dan test verisini al
      const storedData = localStorage.getItem(this.storageKey);
      let startTime = now;
      let usedTime = 0;
      let lastResetTime = now;
      
      if (storedData) {
        try {
          // Kayıtlı veriyi parse et
          const data = JSON.parse(storedData);
          startTime = data.startTime || now;
          usedTime = data.usedTime || 0;
          lastResetTime = data.lastResetTime || now;
        } catch (error) {
          console.error('Test zamanı verisi parse edilemedi:', error);
          // Hata durumunda yeni bir kayıt oluştur
          this.startNewSession();
          return { 
            allowed: true,
            remainingTimeMs: this.testDurationMs,
            nextResetTimeMs: this.resetDurationMs
          };
        }
      } else {
        // Kayıt yoksa yeni oluştur
        this.startNewSession();
        return { 
          allowed: true,
          remainingTimeMs: this.testDurationMs,
          nextResetTimeMs: this.resetDurationMs
        };
      }
      
      // Son sıfırlamadan bu yana geçen süre
      const timeSinceLastReset = now - lastResetTime;
      
      // Eğer sıfırlama süresi geçtiyse, kullanım süresini sıfırla
      if (timeSinceLastReset >= this.resetDurationMs) {
        // Kaç kez sıfırlama yapılması gerektiğini hesapla
        const resetCount = Math.floor(timeSinceLastReset / this.resetDurationMs);
        
        // En son sıfırlama zamanını güncelle
        const newLastResetTime = lastResetTime + (resetCount * this.resetDurationMs);
        
        // Yeni bir oturum başlat, ama süre bilgilerini sıfırla
        localStorage.setItem(this.storageKey, JSON.stringify({
          startTime: now,
          usedTime: 0,
          lastResetTime: newLastResetTime
        }));
        
        // Yeni bir oturum başlatıldı, tam kullanım süresi kaldı
        return { 
          allowed: true,
          remainingTimeMs: this.testDurationMs,
          nextResetTimeMs: this.resetDurationMs
        };
      }
      
      // Test süresi dolmuş mu kontrol et
      if (usedTime >= this.testDurationMs) {
        // Bir sonraki sıfırlanmaya kalan süre
        const nextResetTime = this.resetDurationMs - timeSinceLastReset;
        
        return { 
          allowed: false, 
          message: `Test süreniz doldu. ${Math.ceil(nextResetTime / 60000)} dakika sonra tekrar deneyebilirsiniz.`,
          remainingTimeMs: 0,
          nextResetTimeMs: nextResetTime
        };
      }
      
      // Kullanıcının kullanabileceği kalan süreyi hesapla
      const remainingTime = this.testDurationMs - usedTime;
      
      // Bir sonraki sıfırlamaya kalan süre
      const nextResetTime = this.resetDurationMs - timeSinceLastReset;
      
      // Kullanım süresini güncelle
      this.updateUsedTime(usedTime);
      
      return { 
        allowed: true,
        remainingTimeMs: remainingTime,
        nextResetTimeMs: nextResetTime
      };
    }
    
    /**
     * Kullanım süresini günceller
     * Bu method her X saniyede bir çağrılmalı
     */
    private updateUsedTime(currentUsedTime: number): void {
      const now = Date.now();
      const storedData = localStorage.getItem(this.storageKey);
      
      if (storedData) {
        try {
          const data = JSON.parse(storedData);
          const startTime = data.startTime || now;
          const lastResetTime = data.lastResetTime || now;
          
          // Son kontrol yapıldığından beri 1 saniye ekle
          // Bu sayede kullanıcı aktif olarak kullanıyorsa süre azalır
          const newUsedTime = currentUsedTime + 1000; // 1 saniye ekle
          
          localStorage.setItem(this.storageKey, JSON.stringify({
            startTime,
            usedTime: newUsedTime,
            lastResetTime
          }));
        } catch (error) {
          console.error('Kullanım süresi güncellenirken hata:', error);
        }
      }
    }
    
    /**
     * Yeni bir test oturumu başlatır
     * Bu, kullanım süresini sıfırlar
     */
    public startNewSession(): void {
      const now = Date.now();
      localStorage.setItem(this.storageKey, JSON.stringify({
        startTime: now,
        usedTime: 0,
        lastResetTime: now
      }));
    }
    
    /**
     * Kalan test süresini milisaniye cinsinden döndürür
     * @returns Kalan süre (ms) veya süre dolmuşsa 0
     */
    public getRemainingTime(): number {
      const result = this.checkLimit();
      return result.remainingTimeMs || 0;
    }
    
    /**
     * Bir sonraki sıfırlamaya kalan süreyi milisaniye cinsinden döndürür
     * @returns Kalan süre (ms)
     */
    public getNextResetTime(): number {
      const result = this.checkLimit();
      return result.nextResetTimeMs || 0;
    }
    
    /**
     * Test süresini temizler
     * Bir sonraki kontrolde yeni bir oturum başlatılır
     */
    public clearSession(): void {
      localStorage.removeItem(this.storageKey);
    }
  }
  
  export default TimeLimiter;