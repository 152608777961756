// src/pages/Terms.tsx
import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { useLanguage } from '../context/LanguageContext';
import { usePixabay } from '../hooks/usePixabay';


const Terms = () => {
  const { t } = useLanguage();
  const { imageUrl: heroImage, loading: heroLoading } = usePixabay('legal document contract', 'horizontal');

  const responsibilities = [
    "Toplantılarda ses kaydı ve çeviri için gerekli izinlere sahip olduğunuzdan emin olun.",
    "CSC Voice AI'ı yasa dışı veya yetkisiz amaçlar için kullanmayın.",
    "Microsoft Teams hesap bilgilerinizi güvende tutun."
  ];

  return (
    <div className="container-fluid-custom">
      <div 
        className="terms-hero py-5" 
        style={{
          backgroundImage: heroImage ? `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${heroImage})` : undefined,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        {heroLoading ? (
          <Container className="text-center py-5">
            <Spinner animation="border" variant="light" />
          </Container>
        ) : (
          <Container className="text-center text-white py-5">
            <h1 className="display-4">{t('terms.title')}</h1>
            <p className="lead">{t('terms.effectiveDate')}</p>
          </Container>
        )}
      </div>

      <Container className="py-5">
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="terms-content">
              <section className="mb-5">
                <div className="section-icon mb-4">
                <i className="bi bi-file-text text-primary" style={{ fontSize: "40px" }}></i>
                </div>
                <h2 className="text-primary mb-4">{t('terms.introduction.title')}</h2>
                <p className="mb-4">
                  CSC Voice AI'a hoş geldiniz. Bu uygulama CSC Bilişim Tic. Ltd. Şti.'nin bir ürünüdür. 
                  Uygulamamızı kullanarak bu Kullanım Koşullarını kabul etmiş olursunuz. Kabul etmiyorsanız, 
                  lütfen kullanımı hemen durdurun.
                </p>
              </section>

              <section className="mb-5">
                <div className="section-icon mb-4">
                <i className="bi bi-shield text-primary" style={{ fontSize: "40px" }}></i>
                </div>
                <h2 className="text-primary mb-4">{t('terms.license.title')}</h2>
                <p className="mb-4">
                  Size CSC Voice AI'ı yalnızca amaçlanan kullanımı için sınırlı, münhasır olmayan ve 
                  devredilemez bir lisans veriyoruz: Microsoft Teams toplantılarında gerçek zamanlı 
                  çok dilli çeviriler sağlamak.
                </p>
              </section>

              <section className="mb-5">
                <div className="section-icon mb-4">
                <i className="bi bi-person-check text-primary" style={{ fontSize: "40px" }}></i>
                </div>
                <h2 className="text-primary mb-4">{t('terms.userResponsibilities.title')}</h2>
                <ul className="list-unstyled">
                  {responsibilities.map((item, index) => (
                    <li key={index} className="mb-3 d-flex align-items-center">
                      <i className="bi bi-check-circle-fill text-primary me-2"></i>
                      {item}
                    </li>
                  ))}
                </ul>
              </section>

              <section className="mb-5">
                <h2 className="text-primary mb-4">{t('terms.liability.title')}</h2>
                <p className="mb-4">
                  CSC Bilişim Tic. Ltd. Şti., CSC Voice AI'ın kullanımından veya kullanılamamasından 
                  kaynaklanan hasarlardan sorumlu değildir. Uygulamayı kullanmanız kendi sorumluluğunuzdadır.
                </p>
              </section>

              <section className="mb-5">
                <h2 className="text-primary mb-4">{t('terms.modifications.title')}</h2>
                <p className="mb-4">
                  Bu Kullanım Koşullarını herhangi bir zamanda değiştirme hakkını saklı tutarız. 
                  Değişiklikler yapıldıktan sonra CSC Voice AI'ı kullanmaya devam etmeniz, 
                  revize edilmiş koşulları kabul ettiğiniz anlamına gelir.
                </p>
              </section>

            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Terms;