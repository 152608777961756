// src/components/Icon.tsx dosyasını oluşturun
import React from 'react';
import { IconBaseProps } from 'react-icons';

// IconType'ı any olarak tanımlayarak TypeScript'in tip kontrolünü gevşetiyoruz
type AnyIcon = any;

interface IconProps extends IconBaseProps {
  icon: AnyIcon;
}

const Icon: React.FC<IconProps> = ({ icon: IconComponent, ...props }) => {
  return <IconComponent {...props} />;
};

export default Icon;