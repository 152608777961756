// src/pages/Home.tsx
import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Card, Spinner, Form, Alert, Badge } from 'react-bootstrap';
import { useLanguage } from '../context/LanguageContext';
import { usePixabay } from '../hooks/usePixabay';
import { useNavigate } from 'react-router-dom';
import Popup from '../components/Popup';
// Import both services - we'll try to use Azure first, then fall back to browsers
import AzureSpeechService from '../services/AzureSpeechService';
import BrowserSpeechService from '../services/BrowserSpeechService';
import AzureTextToSpeechService from '../services/AzureTextToSpeechService';
import { LanguageOption } from '../services/SpeechServiceInterfaces';
import { checkBrowserCompatibility } from '../utils/BrowserSupportCheck';
import TimeLimiter from '../utils/TimeLimiter';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { t, language } = useLanguage();
  const { imageUrl: heroImage, loading: heroLoading } = usePixabay('business meeting technology Azure Open AI Microsoft Teams', 'horizontal');
  const { imageUrl: feature1Image, loading: feature1Loading } = usePixabay('voice recognition technology', 'horizontal');
  const { imageUrl: feature2Image, loading: feature2Loading } = usePixabay('microsoft teams meeting', 'horizontal');
  
  // State for speech recognition and translation
  const [sourceLanguage, setSourceLanguage] = useState('en-US');
  const [targetLanguage, setTargetLanguage] = useState('tr-TR');
  const [recognizedText, setRecognizedText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [usingFallback, setUsingFallback] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  
  // State for time limiter
  const [testLimitExceeded, setTestLimitExceeded] = useState(false);
  const [limitMessage, setLimitMessage] = useState('');
  const [remainingTime, setRemainingTime] = useState<number | null>(null);
  const [nextResetTime, setNextResetTime] = useState<number | null>(null);

  
  // State for text-to-speech
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [ttsError, setTtsError] = useState('');
  
  // Reference to store Speech Service instance with proper typing
  const speechServiceRef = useRef<AzureSpeechService | BrowserSpeechService | null>(null);
  
  // Reference for time limiter
  const timeLimiterRef = useRef<TimeLimiter | null>(null);
  
  // Reference for text-to-speech service
  const ttsServiceRef = useRef<AzureTextToSpeechService | null>(null);
  
  // Available languages
  const azureLanguages: LanguageOption[] = [
    { code: 'en-US', name: 'English (US)' },
    { code: 'tr-TR', name: 'Turkish' },
    { code: 'es-ES', name: 'Spanish' },
    { code: 'fr-FR', name: 'French' },
    { code: 'de-DE', name: 'German' },
    { code: 'it-IT', name: 'Italian' },
    { code: 'ja-JP', name: 'Japanese' },
    { code: 'ko-KR', name: 'Korean' },
    { code: 'zh-CN', name: 'Chinese (Simplified)' },
    { code: 'ru-RU', name: 'Russian' },
  ];
  
  // Check browser compatibility and setup time limiter on mount
  useEffect(() => {
    const checkCompatibility = async () => {
      const { isCompatible, errorMessage } = await checkBrowserCompatibility();
      if (!isCompatible) {
        setErrorMessage(errorMessage);
      }
    };
    
    checkCompatibility();
    
    // Initialize time limiter
    timeLimiterRef.current = new TimeLimiter(5, 30); // 5 dakikalık süre, 30 dakikada bir sıfırlama
    
    // Initial check
    checkTimeLimit();
    
    // Set up countdown interval
    const countdownInterval = window.setInterval(() => {
      checkTimeLimit();
    }, 1000);
    
    // Initialize Text-to-Speech service
    const subscriptionKey = '9qBhM45nuEmfzTDDTyJFSl0j7e8f317z9z3BUxTrbhD1qL4H8dFMJQQJ99AJAC5RqLJXJ3w3AAAYACOGfJHi'; // Aynı Speech Service anahtarını kullanabilirsiniz
    const region = 'westeurope'; // Aynı bölgeyi kullanın
    
    ttsServiceRef.current = new AzureTextToSpeechService(subscriptionKey, region);
    
    // Cleanup on unmount
    return () => {
      if (countdownInterval) {
        window.clearInterval(countdownInterval);
      }
      
      if (ttsServiceRef.current) {
        ttsServiceRef.current.stopSpeaking();
      }
      
      if (speechServiceRef.current) {
        speechServiceRef.current.stopSpeechRecognition();
      }
    };
  }, []);
  
  // Time limit check function
  const checkTimeLimit = () => {
    if (!timeLimiterRef.current) return true;
    
    const result = timeLimiterRef.current.checkLimit();
    
    if (!result.allowed) {
      setTestLimitExceeded(true);
      setLimitMessage(result.message || 'Test süreniz doldu.');
      setRemainingTime(0);
      
      // Bir sonraki sıfırlamaya kalan süreyi kaydet
      if (result.nextResetTimeMs !== undefined) {
        setNextResetTime(result.nextResetTimeMs);
      }
      
      return false;
    }
    
    if (result.remainingTimeMs !== undefined) {
      setRemainingTime(result.remainingTimeMs);
    }
    
    // Bir sonraki sıfırlamaya kalan süreyi kaydet
    if (result.nextResetTimeMs !== undefined) {
      setNextResetTime(result.nextResetTimeMs);
    }
    
    setTestLimitExceeded(false);
    return true;
  };
  
  
  // Format remaining time for display
  const formatRemainingTime = (ms: number): string => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  // Bir sonraki sıfırlama için format fonksiyonu ekleyin
  const formatNextResetTime = (ms: number): string => {
  const minutes = Math.floor(ms / 60000);
  const seconds = Math.floor((ms % 60000) / 1000);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};
  
  // Initialize Speech Service on component mount
  useEffect(() => {
    const initSpeechService = () => {
      try {
        // Azure Speech Service credentials
        const subscriptionKey = '9qBhM45nuEmfzTDDTyJFSl0j7e8f317z9z3BUxTrbhD1qL4H8dFMJQQJ99AJAC5RqLJXJ3w3AAAYACOGfJHi'; // Speech Service anahtarınızı buraya ekleyin
        const region = 'westeurope';
        
        // Azure Translator credentials - doğru anahtar ve bölge
        const translatorKey = 'AAzgUJTaz9kanUktd6RBCeLugLFAwcV450bH2Ho2xsq4potwPzhaJQQJ99AJAC5RqLJXJ3w3AAAbACOG4sRG'; // Gerçek API Key 1
        const translatorRegion = 'westeurope';
        
        // Check if we have valid Azure credentials
        if (subscriptionKey && subscriptionKey !== '9qBhM45nuEmfzTDDTyJFSl0j7e8f317z9z3BUxTrbhD1qL4H8dFMJQQJ99AJAC5RqLJXJ3w3AAAYACOGfJHi') {
          speechServiceRef.current = new AzureSpeechService(subscriptionKey, region, translatorKey, translatorRegion);
          console.log("Using Azure Speech Service");
        } else {
          // Fall back to browser's built-in speech recognition, but still try to use Azure Translator
          speechServiceRef.current = new BrowserSpeechService(translatorKey, translatorRegion);
          setUsingFallback(true);
          console.log("Using Browser Speech API (fallback mode)");
        }
      } catch (error) {
        console.error("Failed to initialize speech service:", error);
        // Try browser fallback
        try {
          // Hala gerçek Translator anahtarı ile fallback
          const translatorKey = 'AAzgUJTaz9kanUktd6RBCeLugLFAwcV450bH2Ho2xsq4potwPzhaJQQJ99AJAC5RqLJXJ3w3AAAbACOG4sRG';
          const translatorRegion = 'westeurope';
          
          speechServiceRef.current = new BrowserSpeechService(translatorKey, translatorRegion);
          setUsingFallback(true);
          console.log("Using Browser Speech API (fallback mode after error)");
        } catch (fallbackError) {
          console.error("Failed to initialize browser fallback:", fallbackError);
        }
      }
    };
    
    initSpeechService();
  }, []);

  // Mikrofon butonunu devre dışı bırakma fonksiyonu (hata sonrası geçici olarak)
  const disableButtonTemporarily = (duration = 2000) => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, duration);
  };

  // Function to start speech recognition with improved handling
  const startSpeechRecognition = async () => {
    // If button is disabled, prevent action
    if (isButtonDisabled) return;
    
    // Check time limit
    if (!checkTimeLimit()) {
      return;
    }
    
    // Clear any previous error messages and translated text
    setErrorMessage('');
    setRecognizedText('');
    setTranslatedText('');
    setTtsError('');
    
    if (!speechServiceRef.current) {
      setErrorMessage("The voice recognition service is unavailable. Please refresh the page and try again.");
      return;
    }
    
    // Disable button immediately to prevent multiple clicks
    setIsButtonDisabled(true);
    
    // Request microphone permission first
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // Stop the stream right away, we just needed the permission
      stream.getTracks().forEach(track => track.stop());
    } catch (error) {
      console.error("Permission could not be obtained from the microphone:", error);
      setErrorMessage("Microphone access was denied. Please allow microphone access and try again.");
      setIsButtonDisabled(false); // Re-enable button
      return;
    }
    
    // Update visual state
    setIsRecording(true);
    setIsProcessing(true);
    
    try {
      // Wait a bit longer before trying to start
      setTimeout(() => {
        try {
          // Start speech recognition with the service
          const success = speechServiceRef.current?.startSpeechRecognition(
            sourceLanguage,
            targetLanguage,
            // Callback for recognized text - bu kısmı zaten doğru şekilde yapıyorsunuz
            (text: string) => {
              setRecognizedText(prevText => prevText ? `${prevText} ${text}` : text);
            },
            // Callback for translated text - burada değişiklik yapıyoruz
            (text: string) => {
              // Yeni çevirilen metni önceki çevirinin altına ekleyin
              setTranslatedText(prevText => {
                // Eğer önceki metin yoksa, yeni metni doğrudan ayarla
                if (!prevText) return text;
                
                // Önceki metin varsa, yeni satırla ayırarak ekleyin
                return `${prevText}\n${text}`;
              });
              setIsProcessing(false);
            },
            // Error callback - bu kısım aynı kalıyor
            (error: string) => {
              console.error("Speech recognition error:", error);
              setIsProcessing(false);
              setIsRecording(false);
              setErrorMessage(`Error: ${error}. Please check your microphone and try again.`);
              
              // Kısa bir süre için butonu devre dışı bırak
              disableButtonTemporarily(3000);
            }
          );
          
          if (!success) {
            setIsRecording(false);
            setIsProcessing(false);
            setErrorMessage("Speech recognition could not be initiated. Please refresh the page and try again.");
            disableButtonTemporarily(3000);
          } else {
            // Başarıyla başlatıldıysa butonu aktifleştir
            setIsButtonDisabled(false);
          }
        } catch (error) {
          console.error("Error when initializing speech recognition:", error);
          setIsRecording(false);
          setIsProcessing(false);
          setErrorMessage("An unexpected error occurred during speech recognition. Please try again.");
          disableButtonTemporarily(3000);
        }
      }, 800); // Daha uzun bir gecikme (800ms)
    } catch (error) {
      console.error("Critical error:", error);
      setIsRecording(false);
      setIsProcessing(false);
      setErrorMessage("A critical error occurred during speech recognition. Please refresh the page and try again.");
      disableButtonTemporarily(3000);
    }
  };

  // Function to stop speech recognition with improved handling
  const stopSpeechRecognition = () => {
    // If button is disabled, prevent action
    if (isButtonDisabled) return;
    
    // Check time limit
    if (!checkTimeLimit()) {
      return;
    }
    
    // Temporarily disable button
    setIsButtonDisabled(true);
    
    try {
      if (speechServiceRef.current) {
        speechServiceRef.current.stopSpeechRecognition();
      }
      
      // Update visual state
      setIsRecording(false);
      
      // Re-enable button after a delay
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 1500);
    } catch (error) {
      console.error("Error during the stop:", error);
      setIsRecording(false);
      setErrorMessage("An error occurred while speech recognition was being stopped. Please refresh the page and try again.");
      disableButtonTemporarily(3000);
    }
  };
  
  // Function to speak translated text
  const speakTranslatedText = () => {
    // Süre kontrolü
    if (!checkTimeLimit()) {
      return;
    }
    
    // Çevirisi yapılmış metin yoksa veya düğme devre dışıysa ses çalma
    if (!translatedText || isButtonDisabled || isSpeaking) {
      return;
    }
    
    // TTS servisi kontrol
    if (!ttsServiceRef.current) {
      setTtsError("The voice synthesis service could not be started");
      return;
    }
    
    // Seslendirmeden önce mevcut seslendirmeyi durdur
    stopSpeaking();
    
    // Çevirisi yapılmış metni hedef dilde seslendir
    setIsSpeaking(true);
    setTtsError('');
    
    ttsServiceRef.current.speakText(
      translatedText,
      targetLanguage, // Hedef dilde seslendir
      () => {
        console.log('The voice-over has started');
      },
      () => {
        console.log('Voice acting is completed');
        setIsSpeaking(false);
      },
      (error) => {
        console.error('Voiceover error:', error);
        setTtsError(`Voiceover error: ${error}`);
        setIsSpeaking(false);
      }
    );
  };

  // Function to stop speaking
  const stopSpeaking = () => {
    if (ttsServiceRef.current) {
      ttsServiceRef.current.stopSpeaking();
      setIsSpeaking(false);
    }
  };

  const features = [
    {
      title: t('home.features.translation.title'),
      description: t('home.features.translation.description'),
      query: 'technology communication'
    },
    {
      title: t('home.features.languages.title'),
      description: t('home.features.languages.description'),
      query: 'world languages'
    },
    {
      title: t('home.features.teams.title'),
      description: t('home.features.teams.description'),
      query: 'video conference'
    }
  ];

  const keyPoints = language === 'en' ? [
    'Real-time Speech Translation',
    '24+ Language Support',
    'High Accuracy Rate',
    'Easy Integration',
    'Secure and Private',
    'Instant Transcription'
  ] : [
    'Gerçek zamanlı ses çevirisi',
    '24+ dil desteği',
    'Yüksek doğruluk oranı',
    'Kolay entegrasyon',
    'Güvenli ve özel',
    'Anlık transkripsiyon'
  ];

  return (
    <div className="container-fluid-custom">
      <Popup />

      {/* Hero Section */}
      <div 
        className="hero-section"
        style={{
          backgroundImage: heroImage ? `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${heroImage})` : undefined,
        }}
      >
        {heroLoading ? (
          <Container className="text-center py-5">
            <Spinner animation="border" variant="light" />
          </Container>
        ) : (
          <Container>
            <Row className="align-items-center min-vh-75">
              <Col lg={8} className="text-white">
                <h1 className="display-4 fw-bold mb-4">{t('home.title')}</h1>
                <p className="lead fs-lg mb-4">{t('home.description')}</p>
                <div className="d-flex gap-3">
                  <Button 
                    variant="light" 
                    size="lg"
                    onClick={() => navigate('/packages')} 
                    className="d-inline-flex align-items-center"
                  >
                    {t('home.getStarted')}
                    <i className="bi bi-arrow-right ms-2"></i>
                    </Button>
                  <Button 
                    variant="outline-light" 
                    size="lg"
                    href="/CSCVoiceAI_Product_Document_v1.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-inline-flex align-items-center"
                  >
                    <i className="bi bi-file-text me-2"></i>
                    {t('home.docuviewButton')}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>

      {/* Speech Translation Demo Section */}
      <div className="content-section bg-light py-5">
        <Container>
          <h2 className="text-center display-5 mb-4">Try to CSC Voice AI - Real-time Meeting Translation & Transcription</h2>
          <p className="lead text-center mb-5">
            Experience real-time speech recognition and translation powered by Cloud Solutions Company LTD.
          </p>
          
          {/* Test süre uyarısı */}
          {testLimitExceeded && (
            <Alert variant="warning" className="mb-4">
              <strong>{limitMessage}</strong>
              {nextResetTime !== null && nextResetTime > 0 && (
                <p className="mb-0 mt-2">
                  Your test period {formatNextResetTime(nextResetTime)} then it will be renewed.
                </p>
              )}
            </Alert>
          )}
          
          {/* Kalan test süresi göstergesi */}
          {!testLimitExceeded && remainingTime !== null && (
            <div className="d-flex justify-content-center mb-3">
              <Badge bg="info" className="px-3 py-2 me-2">
              The Remaining Test Period: {formatRemainingTime(remainingTime)}
              </Badge>
              {nextResetTime !== null && nextResetTime > 0 && (
                <Badge bg="secondary" className="px-3 py-2">
                  Reneval: {formatNextResetTime(nextResetTime)}
                </Badge>
              )}
            </div>
          )}
          
          {usingFallback && (
            <Alert variant="info" className="mb-3 text-center">
            <i className="bi bi-info-circle me-2"></i>
            Running in browser mode. For best results, configure Azure Speech Services with CSC Voice AI
            </Alert>
          )}
          
          {errorMessage && (
            <Alert variant="danger" className="mb-3">
              {errorMessage}
            </Alert>
          )}
          
          <Row className="justify-content-center mb-4">
            <Col md={10} lg={8}>
              <Card className="shadow-sm border-0">
                <Card.Body className="p-4">
                  <Row className="mb-4">
                    <Col md={6} className="mb-3 mb-md-0">
                      <Form.Group>
                        <Form.Label>Select Speech Recognition Language</Form.Label>
                        <Form.Select 
                          value={sourceLanguage}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSourceLanguage(e.target.value)}
                          disabled={isRecording || testLimitExceeded}
                        >
                          {azureLanguages.map((lang) => (
                            <option key={`source-${lang.code}`} value={lang.code}>
                              {lang.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Select Translation Language</Form.Label>
                        <Form.Select 
                          value={targetLanguage}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setTargetLanguage(e.target.value)}
                          disabled={isRecording || testLimitExceeded}
                        >
                          {azureLanguages.map((lang) => (
                            <option key={`target-${lang.code}`} value={lang.code}>
                              {lang.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  
                  <div className="d-flex justify-content-center mb-4">
                    {!isRecording ? (
                      <Button 
                        variant="primary" 
                        size="lg" 
                        className="rounded-circle p-3"
                        onClick={startSpeechRecognition}
                        disabled={isButtonDisabled || isProcessing || testLimitExceeded}
                        title={testLimitExceeded ? limitMessage : (isButtonDisabled ? "Please wait..." : "Start recording")}
                      >
                        <i className="bi bi-mic" style={{ fontSize: "24px" }}></i>
                        <span className="visually-hidden">Kayda başla</span>
                      </Button>
                    ) : (
                      <Button 
                        variant="danger" 
                        size="lg" 
                        className="rounded-circle p-3"
                        onClick={stopSpeechRecognition}
                        disabled={isButtonDisabled || testLimitExceeded}
                        title={testLimitExceeded ? limitMessage : (isButtonDisabled ? "Please wait..." : "Stop recording")}
                      >
                        <i className="bi bi-stop-circle" style={{ fontSize: "24px" }}></i>
                        <span className="visually-hidden">Kaydı durdur</span>
                      </Button>
                    )}
                  </div>
                  
                  <Row>
                    <Col md={6} className="mb-3 mb-md-0">
                      <div className="border rounded p-3 h-100">
                        <h5>Recognized Text</h5>
                        <div className="mt-2" style={{ minHeight: '100px' }}>
                          {isProcessing && !recognizedText ? (
                            <div className="d-flex justify-content-center align-items-center h-100">
                              <Spinner animation="border" size="sm" className="me-2" />
                              <span>Listening...</span>
                            </div>
                          ) : (
                            <p className="mb-0">{recognizedText}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                    <div className="border rounded p-3 h-100">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <h5>Translated Text</h5>
                        {translatedText && (
                          <div>
                            {!isSpeaking ? (
                              <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={speakTranslatedText}
                                disabled={isButtonDisabled || testLimitExceeded || !translatedText}
                                className="d-inline-flex align-items-center"
                              >
                                <i className="bi bi-volume-up me-1"></i> Dinle
                              </Button>
                            ) : (
                              <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={stopSpeaking}
                                className="d-inline-flex align-items-center"
                              >
                                <i className="bi bi-stop-fill me-1"></i> Durdur
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="mt-2" style={{ minHeight: '100px' }}>
                        {isProcessing && recognizedText && !translatedText ? (
                          <div className="d-flex justify-content-center align-items-center h-100">
                            <Spinner animation="border" size="sm" className="me-2" />
                            <span>Translating...</span>
                          </div>
                        ) : (
                          <>
                            {/* Çeviri metni için beyaz boşlukları ve satır sonlarını koruyan stil */}
                            <p className="mb-0" style={{ whiteSpace: 'pre-wrap' }}>{translatedText}</p>
                            {ttsError && <Alert variant="danger" className="mt-2 p-1 small">{ttsError}</Alert>}
                            {/* TTS kontrolleri için özel div */}
                            <div className="tts-controls mt-2"></div>
                          </>
                        )}
                      </div>
                    </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Features Section */}
      <div className="content-section">
        <Container>
          <h2 className="text-center display-5 mb-5">{t('home.features.title')}</h2>
          <Row className="g-4">
            {features.map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </Row>
        </Container>
      </div>

      {/* First Feature Highlight */}
      <div className="content-section-alt">
        <Row className="g-0">
          <Col md={6} className="order-md-2">
            <div className="image-section">
              {feature1Loading ? (
                <div className="d-flex align-items-center justify-content-center h-100">
                  <Spinner animation="border" />
                </div>
              ) : (
                <img 
                  src={feature1Image || ''} 
                  alt="Real-time Translation"
                  className="w-100 h-100 object-fit-cover" 
                />
              )}
            </div>
          </Col>
          <Col md={6} className="order-md-1">
            <div className="text-content">
              <h2 className="h1 mb-4">{t('home.features.translation.title')}</h2>
              <p className="lead mb-4">{t('home.features.translation.description')}</p>
              <ul className="feature-list">
                {keyPoints.slice(0, 3).map((point, index) => (
                  <li key={index} className="mb-3 d-flex align-items-center">
                    <i className="bi bi-check text-primary me-2" style={{ fontSize: "24px" }}></i>
                    {point}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </div>

      {/* Second Feature Highlight */}
      <div className="content-section">
        <Row className="g-0">
          <Col md={6}>
            <div className="image-section">
              {feature2Loading ? (
                <div className="d-flex align-items-center justify-content-center h-100">
                  <Spinner animation="border" />
                </div>
              ) : (
                <img 
                  src={feature2Image || ''} 
                  alt="Teams Integration"
                  className="w-100 h-100 object-fit-cover" 
                />
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="text-content">
              <h2 className="h1 mb-4">{t('home.features.teams.title')}</h2>
              <p className="lead mb-4">{t('home.features.teams.description')}</p>
              <ul className="feature-list">
                {keyPoints.slice(3).map((point, index) => (
                  <li key={index} className="mb-3 d-flex align-items-center">
                    <i className="bi bi-check text-primary me-2" style={{ fontSize: "24px" }}></i>
                    {point}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </div>

      {/* Documentation Section */}
      <div className="content-section-alt py-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} className="text-center">
              <h2 className="display-6 mb-4">{t('home.docutitle')}</h2>
              <p className="lead mb-4">{t('home.docudescription')}</p>
              <Button 
                variant="primary" 
                size="lg"
                href="/CSCVoiceAI_Product_Document_v1.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="d-inline-flex align-items-center"
              >
                <i className="bi bi-file-text me-2"></i>
                {t('home.docuviewButton')}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>

      {/* CTA Section */}
      <div className="content-section text-center py-5">
        <Container>
          <h2 className="display-5 mb-4">{t('home.features.hemenbasla.title')}</h2>
          <p className="lead mb-4">{t('home.features.hemenbasla.description')}</p>
          <Button 
            variant="primary" 
            size="lg" 
            onClick={() => navigate('/packages')}
            className="d-inline-flex align-items-center"
          >
            {t('home.features.hemenbasla.hemenbuton')}
            <i className="bi bi-arrow-right ms-2"></i>
          </Button>
        </Container>
      </div>
    </div>
  );
};

interface FeatureCardProps {
  title: string;
  description: string;
  query: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, description, query }) => {
  const { imageUrl, loading } = usePixabay(query);

  return (
    <Col lg={4} className="mb-4">
      <Card className="h-100 shadow-sm hover-card border-0">
        {loading ? (
          <div className="text-center p-5">
            <Spinner animation="border" />
          </div>
        ) : (
          <Card.Img 
            variant="top" 
            src={imageUrl || ''} 
            alt={title}
            className="feature-image"
            style={{ height: '200px', objectFit: 'cover' }}
          />
        )}
        <Card.Body>
          <Card.Title className="h4 mb-3">{title}</Card.Title>
          <Card.Text className="text-muted">{description}</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default Home;